<template>
    <div class="taskdetail" :style="{'--themecolor':themecolors}">
         <div class="top">
            <h2>06-20 <span>Date</span></h2>
            <div class="time flex-center">
                <p class="flex-center"><img src="@/assets/RiCheng/white_time.svg" alt="">11:00-11:40</p>
                <p class="flex-center"><img src="@/assets/RiCheng/site.svg" alt="">南翔希尔顿A厅</p>
            </div>
            <p>分会场二：脑神经研究分会场2-讲座6</p>
        </div>
        <div class="intro">
            <div class="title">
                <p>主持</p>
            </div>
            <div class="intro-info flex-center">
                <div class="info-item" v-for="i in 8" :key="i">
                    <p class="img-box flex-center">
                        <img src="https://img01.yzcdn.cn/vant/cat.jpeg" alt="">
                    </p>
                    <p>王芳</p>
                </div>
            </div>
        </div>
        <div class="bottom">
            <div class="timeline" v-for="i in 5" :key="i">
                <p class="time flex-center"><img src="@/assets/RiCheng/time.svg" alt="">08:00-08:30</p>
                <div class="content">
                    <div class="border">
                        <p class="text">关于麻醉学研究的几个问题 A few questions about anesthesiology research</p>
                        <div class="flex-center">
                            <div class="type-border"></div>
                            <div class="touxiang flex-center"><img src="https://img01.yzcdn.cn/vant/cat.jpeg" alt=""></div>
                            <p>Aman</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState(['themecolors'])
    }
}
</script>

<style lang="less" scoped>
@Color:var(--themecolor);
@bgColor:var(--themecolor);

.flex-center{
  display: flex;
  align-items: center;
}
.between{
    justify-content: space-between;
}

.taskdetail{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: #F7F8FA;
    overflow-y: scroll;
    .top{
        background: @bgColor;
        color: #fff;
        padding:.1rem .2rem;
        h2{ 
            font-size: .32rem;
            font-weight: 500;
            span{
                font-size: .2rem;
                font-weight: normal;
            }
        }
        p{
            flex: 1;
            margin: .1rem 0;
            img{
                width: .12rem;
                margin-right: .1rem;
            }
            font-size: .14rem;
        }
        .time{
            border-bottom:1px solid #fff;
            p:last-child{
                img{ margin-right: .12rem; }
            }
        }
    }
    .intro{
        padding: 0 .2rem .15rem;
        background: #fff;
        margin-bottom: .2rem;
        p{
            font-size: .14rem;
            color: #646566;
        }
        .title{
            font-size: .16rem;
            font-weight: bold;
            padding: .1rem 0;
            margin-bottom: .1rem;
            border-bottom: 1px solid #EBEDF0;
            p{ color: #000; }
        }
    }
    .intro-info{
        width: 100%;
        flex-wrap: wrap;
        .info-item{
            text-align: center;
            margin: .11rem;
        }
        .img-box{
            width: .45rem;
            height: .45rem;
            border-radius: 50%;
            overflow: hidden;
            img{
                width: 100%;
                height: auto;
            }
        }
    }
    .bottom{
        background: #fff;
        padding: .1rem 0;
        margin-top: .2rem;
    }
    .timeline{
        font-size: .16rem;
        background: #fff;
        padding:.1rem .2rem 0;
        .time{
            font-weight: bold;
            color: #646566;
            img{
                width: .15rem;
                margin-right: .2rem;
            }
        }
        .content{
            border-left: 1px solid #EBEDF0;
            margin: .1rem 0;
            margin-left: .07rem;
            font-size: .14rem;
            .border{
                margin-left: .25rem;
                padding: .1rem;
                border: 1px solid #EBEDF0;
                border-radius: 6px;
            }
            .text{ 
                color: #000;
                margin-bottom: .1rem;
            }
            .type-border{
                width: .1rem;
                height: .1rem;
                border:3px solid @Color;
                border-radius: 50%;
            }
            .touxiang{
                width: .4rem;
                height: .4rem;
                border-radius: 50%;
                overflow: hidden;
                margin: 0 .1rem;
                background: #000;
                img{
                    width: 100%;
                }
            }
        }
    }
}
</style>